.GraphicModal{
    
}
.mainBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: scroll;
    border: 2px solid #000;
    min-height: 500px;
    max-height: 550px;
    min-width: 300px;
    width: 800px;
    background-color: #233044;
    padding: 16px;
}

.childBox{
    min-width: 120px;
    height: 100%;
}
.GraphicModalBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}