.custom_button {
    padding: 5px;
    /* height: 38px; */
    border: 0;
    border-radius: 5px;
    color: #fff;
    transition: .3s all ease-in-out;
    background-color: #3d008b;
    width: 100%;
}

.custom_button:hover {
    background-color: #6313ca;
}
 
.custom_button.primary {
    background-color: #1B2635
}
.custom_button.red:hover {
    background-color: #6313ca;
}
.custom_button.red {
    background-color: red;
}
.custom_button.color5252ab75 {
    background-color: #5252ab75;
}
.custom_button.color6dbab0 {
    background-color: #6dbab0;
}
.custom_button.colorc91313 {
    background-color: #c91313;
}
.custom_button.color1B263B {
    background-color: #1B263B;
}
.custom_button.colorBEAEE2 {
    background-color: #BEAEE2;
}
.custom_button.color0e8b00 {
    background-color: #0e8b00;
}
.custom_button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}