/* YourComponent.css (or import your CSS into your component) */

.form-container {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.form-container:hover {
    background-color: transparent;
}

.form-container.appear {
    opacity: 1;
    transform: translateY(0);
}

.select-container,
.input-container {
    flex: 1;
    margin-right: 10px;
}

.material-select,
.material-input,
.material-button {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
    color: #fff;
}

.material-button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.material-button:hover {
    background-color: #0056b3;
}

.material-select:disabled {
    background-color: rgba(0, 0, 0, 0.5);
    /* Change the background color */
    color: #999;
    /* Change the text color */
    cursor: not-allowed;
    /* Change the cursor style */
    /* Add any other styles as needed */
}

/* Styles for the input element when it's disabled */
.material-input:disabled {
    background-color: rgba(0, 0, 0, 0.5);
    /* Change the background color */
    color: #999;
    /* Change the text color */
    cursor: not-allowed;
    /* Change the cursor style */
    /* Add any other styles as needed */
}

.material-select:focus {
    border-color: #007bff;
    /* Change the border color when focused */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Add a box shadow when focused */
    /* Add any other styles as needed */
}

/* Styles for the select dropdown options when the select element is open */
.material-select:focus option {
    background-color: #f0f0f0;
    /* Change the background color of options when open */
    color: #333;
    /* Change the text color of options when open */
    /* Add any other styles as needed */
}

/* Styles for the select dropdown options when they are hovered */
.material-select:focus option:hover {
    background-color: #ddd;
    /* Change the background color of options when hovered */
    color: #000;
    /* Change the text color of options when hovered */
    /* Add any other styles as needed */
}

.material-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    transition: border-color 0.3s ease;
    /* Add transition for border color */
}

/* Styles for the select dropdown options */
.material-select option {
    opacity: 0;
    /* Start with 0 opacity */
    transition: opacity 1s ease;
    /* Add transition for opacity */
}

/* Apply opacity to options when the select is open */
.material-select:focus option {
    opacity: 1;
    /* Change opacity to 1 when select is focused */
}

/* Add more styles and animations as needed */