.orderList {
    width: 100%;
    row-gap: 10px;
  }
  
  .orderList :hover {
    /* transform: scale(1.02); */
    border: 1px solid white;
  }
  
  .orderList div :hover {
    border: none;
  }
  
  .list {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    background: rgb(7, 40, 94, 0.5);
    color: #fff;
    border-radius: 10px;
    display: flex;
    margin-bottom: 10px;
    border: 1px solid #2a3889;
  }
  
  .infoSide {
    box-sizing: border-box;
    width: 2%;
    height: 100%;
  }
  
  .info {
    width: 20px;
    height: 20px;
    border-right: 1px solid #2a3889;
    border-bottom: 1px solid #2a3889;
    border-bottom-right-radius: 60%;
    font-size: x-small;
  }
  
  .listLeftSide {
    box-sizing: border-box;
    height: 100%;
    width: 55%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .docType {
    font-size: large;
  }
  
  .listRightSide {
    box-sizing: border-box;
    width: 42%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .statistics {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .statisticType {
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
  }
  
  .statistics div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttons {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 20px;
    height: 100%;
    width: 10%;
    display: flex;
    justify-content: space-between;
  }
  .button {
    color: #fff;
    border: none;
    background: none;
  }
  