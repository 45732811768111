.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .file-upload {
  height: 20px;
  width: 20px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid #ffffff; */
  overflow: hidden;
  /* background-image: linear-gradient(to bottom, #2590eb 50%, #ffffff 50%); */
  background-size: 100% 200%;
  transition: all 1s;
  color: #2590eb;
  font-size: 100px;
  cursor: pointer;
}

.wrapper .file-upload input[type=file] {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.wrapper .file-upload:hover {
  background-position: 0 -100%;
  color: #2590eb;
}

.root-d .MuiPaper-elevation {
  width: 75% !important
}
.root-d-2 .MuiPaper-elevation {
  width: 25%;
}
.forCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}